export const REPAIR_TYPE = ['Customer Pay', 'Warranty Repair', 'Service Bulletin'];
export const ISSUE_TYPE = ['Customer', 'Production', 'Other'];
export const REPAIR_STATUS = ['Open', 'Processing', 'Resolved'];
export const WORKORDER_STATUS = [
  'New',
  'Hold',
  'Ready For ENG Review',
  'Ready For Materials Review',
  'Ready For Procurement Review',
  'Ready For Production Review',
  'At Upfitter/Supplier',
  'In Production/Print WO',
  'Partial RFD',
  'RFD',
  'With Service',
  'Closed:Abandonded',
  'Closed:Changed',
  'Closed:Completed',
  'Delivered to Customer'
];
export const PRODUCTION_BLOCKED_REASON = ['NO VEHICLE', 'MATERIAL ISSUE', 'NEED ENGINEERING', 'MANPOWER', 'HOLIDAY', 'NEEDS SERVICE'];

export const SALESORDER_STATUS = ['Draft', 'New', 'In Queue', 'Completed', 'Abandoned'];
export const PRIORITY = { Urgent: 'Urgent' };

export const TRANSPORT_STATUS = ['Enroute', 'Delivered'];
export const TRANSPORT_TYPE = [
  { id: 'PortIntake', name: 'Port Intake' },
  { id: 'InventoryTransfer', name: 'Inventory Transfer' },
  { id: 'Sales', name: 'Sales' },
  { id: 'Demo', name: 'Demo' },
  { id: 'Service', name: 'Service' },
  { id: 'Production', name: 'Production' }
];

export const STATUS_MAP = {
  Enroute: 'warning',
  Delivered: 'success',
  Open: 'primary',
  Processing: 'warning',
  Resolved: 'success',
  Complete: 'success',
  New: 'warning',
  Blocked: 'error',
  MissingVIN: 'secondary',
  Default: 'info',
  Urgent: 'warning',
  Hold: 'warning'
};

export const ROLE = {
  None: 'none',
  Production: 'production',
  Transport: 'transport',
  Service: 'service',
  ServiceManager: 'serviceManager',
  Admin: 'admin',
  Vehicle: 'vehicle',
  Contracts: 'contracts',
  Parts: 'parts',
  PartsPlus: 'partsPlus',
  AdminPlus: 'AdminPlus',
  Engineer: 'engineer',
  SalesOrderWrite: 'salesorderWrite',
  SalesOrderRead: 'salesorderRead',
  WorkOrder: 'workorder',
  PartReleaseManager: 'partReleaseManager'
};

export const DESIGN_DOC_TYPE = [
  { id: 'AutoCAD_DWG', name: 'AutoCAD dwg' },
  { id: 'SolidWorks_DRW', name: 'SolidWorks drw' },
  { id: 'SolidWorks_PRT', name: 'SolidWorks prt' },
  { id: 'STP', name: 'stp' },
  { id: 'PDF', name: 'pdf' },
  { id: 'ServiceInstructions', name: 'Service Instructions' },
  { id: 'WorkInstructions', name: 'Work Instructions' },
  { id: 'CKDAssemblyInstructions', name: 'CKD Assembly Instructions' },
  { id: 'SchematicDrawings', name: 'Schematic Drawings' }
];
export const TRUCKBODY_DOC_TYPE = [
  { id: 'WO', name: 'Work Order' },
  { id: 'Quote', name: 'Quote' },
  { id: 'Invoice', name: 'Invoice' },
  { id: 'PlatePic', name: 'Plate Picture' }
];
export const MILEAGE_UNIT = [
  { id: 'km', name: 'KM' },
  { id: 'mile', name: 'Mile' }
];

// OVERSEAS PRODUCTION (managed manually)
// INBOUND TO US (managed manually)
// MFG INVENTORY (previously delivered to MFG)
// RFD (automatic from checking box in production)
// SHIPPING TO CUSTOMER (should be automatic from transport)
// DELIVERED TO CUSTOMER (should be automatic when ETA from transport matches date)

export const VEHCILE_STATUS = [
  'OVERSEAS PRODUCTION',
  'INBOUND TO US',
  'MFG INVENTORY',
  'RFD',
  'SHIPPING TO CUSTOMER',
  'DELIVERED TO CUSTOMER'
];

export const GOOGLE_IMG_HOST = 'https://lh3.googleusercontent.com/d/'; // 'https://drive.google.com/file/d/';

export const PART_STATUS = [
  { id: 'RND', name: 'R&D state' },
  { id: 'PR', name: 'Production released' },
  { id: 'EH', name: 'Engineering Hold' },
  { id: 'OB', name: 'Obsolete' }
];
export const PART_STATUS_PARTSPLUS = [
  { id: 'RND', name: 'R&D state' },
  { id: 'EH', name: 'Engineering Hold' },
  { id: 'OB', name: 'Obsolete' }
];
export const PART_STATUS_MAP = { RND: 'warning', PR: 'success', OB: 'error', EH: 'error' };

export const PART_NUM_PRE = [
  { id: '100', name: '100 Made to Spec Parts' },
  { id: '200', name: '200 Schematics / Layouts / Labels' },
  { id: '300', name: '300 Kit / Assembly' },
  { id: '400', name: '400 Miscellanous' },
  { id: '500', name: '500 Raw Materials / Consumables' },
  { id: '600', name: '600 Software' },
  { id: '700', name: '700 OTS' },
  { id: '750', name: '750 OMS' },
  { id: '800', name: '800 Production tools, jigs' },
  { id: '900', name: '900 Electrical harnesses / Parts' },
  { id: 'DOC-ENG', name: 'DOC-ENG Engineering Documentation' },
  { id: 'WI-ENG', name: 'WI-ENG Work Instructions' }
];

export const UNIT = ['Each', 'Feet', 'Inch', 'cm', 'm'];
export const STATE = ['VW', 'CA', 'Other'];
export const DEALER = ['Dealer', 'Direct', 'Lion Truck body'];
