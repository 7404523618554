// assets
import { DashboardOutlined, CarryOutOutlined, CarOutlined, SettingOutlined, CodeSandboxOutlined } from '@ant-design/icons';
import { ROLE } from 'utils/constants';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: 'group-dashboard',
  title: 'Navigation',
  type: 'group',
  children: [
    {
      id: 'vehicle',
      title: 'Vehicles',
      type: 'item',
      url: '/',
      icon: CarOutlined,
      breadcrumbs: false
    },

    {
      id: 'services',
      title: 'After Sales',
      type: 'item',
      url: '/services',
      icon: CarryOutOutlined,
      breadcrumbs: false
    },
    {
      id: 'transportations',
      title: 'Transportations',
      type: 'item',
      url: '/transportations',
      icon: CarryOutOutlined,
      breadcrumbs: false
    },
    {
      id: 'parts',
      title: 'Parts',
      type: 'item',
      url: '/parts',
      icon: CodeSandboxOutlined,
      breadcrumbs: false
    },
    {
      id: 'salesOrders',
      title: 'Sales Orders',
      type: 'item',
      url: '/salesorders',
      icon: CodeSandboxOutlined,
      breadcrumbs: false,
      allowedRoles: [ROLE.SalesOrderRead, ROLE.SalesOrderWrite]
    },
    {
      id: 'workOrders',
      title: 'Work Orders',
      type: 'item',
      url: '/workOrders',
      icon: CodeSandboxOutlined,
      breadcrumbs: false
    },
    {
      id: 'reports',
      title: 'Reports',
      type: 'item',
      url: '/reports',
      icon: DashboardOutlined,
      breadcrumbs: false
    },
    {
      id: 'configuration',
      title: 'Configuration',
      type: 'item',
      url: '/configuration',
      icon: SettingOutlined,
      breadcrumbs: false
    }
  ]
};

export default dashboard;
