import { ROLE } from './constants';

export const isUserAuthorized = (userRoles, allowedRole) => {
  return userRoles && (userRoles?.includes(ROLE.Admin) || userRoles?.includes(ROLE.AdminPlus) || userRoles?.includes(allowedRole));
};

export const isUserAuthorizedExcludeAdmin = (userRoles, allowedRoles) => {
  const allowedRolesWithAdminPlus = [...allowedRoles, ROLE.AdminPlus];
  const filterRoles = userRoles?.filter((value) => allowedRolesWithAdminPlus?.includes(value));

  return userRoles && filterRoles.length > 0;
};

export const tryParseJSONObject = (jsonString) => {
  try {
    var o = JSON.parse(jsonString);
    if (o && typeof o === 'object') {
      return o;
    }
  } catch (e) {
    return false;
  }

  return false;
};

export const getGoogleDocLink = (fileUrl, isDisplay) => {
  return isDisplay ? `https://drive.google.com/file/d/${fileUrl}/view` : `https://drive.google.com/uc?id=${fileUrl}`;
};
